import React from "react";
import { Link } from "gatsby";
import "../style.css"

import Logo from "../images/icons/logo.svg"

const Navigation = () => {

  return (
    <nav>
        <Link to="/"><img className="logo" src={Logo} alt="logo"/></Link>
        <div className="nav-links">
            <Link to="/about"><p className="link caption">About</p></Link>
            <a href="mailto:michaelwatchmaker@icloud.com"><p className="link caption">Contact</p></a>
        </div>
    </nav>
  );
};

export default Navigation;