import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Layout from "./src/components/layout.js";


const pageTransition = {
    initial: { 
      opacity: 0,
      filter: "blur(8px)",
      y: 40
    },
    animate: { 
      opacity: 1,
      filter: "blur(0px)",
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
        duration: 0.4
      } 
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.4 
      }
    },
  };

export const shouldUpdateScroll = () => false;

export const wrapPageElement = ({ element, props }) => {
  return (
    <AnimatePresence mode="wait">
        <motion.div key={props.location.pathname} variants={pageTransition} initial="initial" animate="animate" exit="exit" onAnimationComplete={() => window.scrollTo(0, 0)}>
            <Layout {...props}>{element}</Layout>
        </motion.div>
    </AnimatePresence>
  );
};