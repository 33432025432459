exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-fotmob-js": () => import("./../../../src/pages/fotmob.js" /* webpackChunkName: "component---src-pages-fotmob-js" */),
  "component---src-pages-goodrx-js": () => import("./../../../src/pages/goodrx.js" /* webpackChunkName: "component---src-pages-goodrx-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-minute-js": () => import("./../../../src/pages/minute.js" /* webpackChunkName: "component---src-pages-minute-js" */),
  "component---src-pages-overhere-js": () => import("./../../../src/pages/overhere.js" /* webpackChunkName: "component---src-pages-overhere-js" */)
}

