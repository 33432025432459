import React from "react";
import "../style.css"

const Footer = () => {

  return (
    <footer>
        <div className="cutout"></div>
        <div className="contact">
            <h2><span style={{opacity: 0.5}}>Open for collaborations and full time roles.</span><br/><a href="mailto:michaelwatchmaker@icloud.com" target="_blank" rel="noreferrer">Email me</a></h2>
        </div>
        <div className="bottom">
            <p className="copyright">&#169; 2025</p>
            <div className="footer-links">
                <a href="https://drive.google.com/file/d/1de34roIluOb5ffRdNpmCMjn2YcAGnr_W/view" target="_blank" rel="noreferrer">Resume</a>
                <a href="https://www.figma.com/@watchmaker" target="_blank" rel="noreferrer">Figma</a>
                <a href="https://www.linkedin.com/in/michaelwatchmaker/" target="_blank" rel="noreferrer">LinkedIn</a>
            </div>
        </div>
    </footer>
  );
};

export default Footer;